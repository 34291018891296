import { TrialTopHeader } from '@iblai/ibl-web-react-common';
import React from 'react';
import useFreeTrial from '../../../hooks/useFreeTrial';
import { useTranslation } from 'react-i18next';

export default function TrialBanner(){
  const {usage, handleUpgrade} = useFreeTrial();
  const { t } = useTranslation();
  const tooltip = t(`You are on a free trial and you have {{usageCount}} questions left
            before you are prompted to make a payment.`, {usageCount: usage?.count});
  const pillDisplay = t(`Upgrade`);
  const description = t(`You are on a free trial. {{usageCount}} questions left`, {usageCount: usage?.count});

  return (
    <div>
      {
        usage &&
        <TrialTopHeader
          handleClick={handleUpgrade}
          tooltipText={tooltip}
          pillDisplayText={pillDisplay}
          descriptionText={description}
          spaUsesMentorSidebar={true}
          fixedClassNamesToMove=".af-view .nav, .right-content"
        />
      }

    </div>
  )
}