import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const WHITELISTED_IFRAME_ORIGINS = [
  process.env.REACT_APP_IBL_MFE_URL,
  process.env.REACT_APP_IBL_MENTOR_IFRAME_WIDGET_ORIGIN_URL,
];

function useIframeMessage() {
  const navigate = useNavigate();
  const { courseID } = useParams();
  useEffect(() => {
    const messageHandler = (event) => {
      if (WHITELISTED_IFRAME_ORIGINS.includes(event.origin)) {
        let data = event.data;
        try {
          data = JSON.parse(data);
          if (data?.reason === 'BOOKMARKED_ITEM_CLICKED' && courseID) {
            navigate(`/courses/${courseID}/course?unit_id=${data.unitId}`);
          }
        } catch (error) {
          // data is not JSON string
        }
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);
}

export default useIframeMessage;
