import { getTenant, getUserName } from '../helpers';
import { api } from '@iblai/ibl-web-react-common';


export function getRecommendedCourses(callback) {
  try {
    api.iblaxdApiCallWrapper(
      (args) =>
        api.iblaxdaimentorapi.aiMentorOrgsUsersRecommendCoursesRetrieve(
          ...(args || [])
        ),
      getTenant(),
      getUserName(),
      { returnCourseData: 'true', includeMainCourses: 'true'}
    )((err, data, response) => (err, callback(data), response));
  } catch (error) {
    callback(false);
  }
}

export function getUserCoursesMetaData(course_id) {
  return new Promise((resolve) => {
    api.ibledxcourses.getCourseMeta({ course_key: course_id }, function (data) {
      resolve(data);
    });
  });
}
