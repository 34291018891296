import { useEffect, useRef, useState } from 'react';
import { getRoles, saveRoleOrSkills } from './helpers';

function useRole() {
  const [searchedRoles, setSearchedRoles] = useState({
    results: [],
    num_pages: 1,
  });
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const hasFetchedData = useRef(false);

  const addNewRole = (data, successCallback, errorCallback) => {
    saveRoleOrSkills(data, (status)=>{
      if([200, 201].includes(status)){
        successCallback()
      }else{
        errorCallback()
      }
    })
  }

  useEffect(() => {
    if (!hasFetchedData.current) {
      getRoles(
        (data) => {
          setSearchedRoles(data);
          setRolesLoaded(true);
        },
        1,
        [],
        ''
      );
      hasFetchedData.current = true;
    }
  }, []);

  return { searchedRoles, setSearchedRoles, rolesLoaded, setRolesLoaded, addNewRole };
}

export default useRole;
