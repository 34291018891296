import React from 'react';
import {
  getFirstAvailableUnit,
  getParentBlockById,
} from '../../hooks/utils/iframe';
import { getParameterByName } from '../../hooks/helpers';
import './CourseIframeSecondaryMenu.css';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';

function getDisplayName(courseData, indices) {
  let current = courseData;
  for (let index of indices) {
    if (current && current.children && current.children[index]) {
      current = current.children[index]; // Navigate to the next level
    } else {
      return undefined;
    }
  }
  return current.display_name;
}

const updateQueryParam = (urlSearchParams, key, value) => {
  const params = new URLSearchParams(urlSearchParams);
  params.set(key, value);
  return params.toString();
};

const CourseIframeSecondaryMenu = ({ courseData, handleOutlineClick }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavLinkClick = (paramValue) => {
    const newSearch = updateQueryParam(location.search, 'unit_id', paramValue);
    console.log('new search ', `${location.pathname}?${newSearch}`);
    console.log('location pathname ', location.pathname);
    navigate(`${location.pathname}?unit_id=${encodeURIComponent(paramValue)}`);
  };
  let data = null;
  if (getParameterByName('unit_id') === null) {
    data = getParentBlockById(
      courseData.children,
      getFirstAvailableUnit(courseData).id
    );
  } else {
    data = getParentBlockById(
      courseData.children,
      getParameterByName('unit_id')
    );
  }
  let indices = data.foundIndices;

  return (
    <div className="secondary_menu">
      <div className="secondary_options">
        <a
          href="#"
          id="about"
          data-id={courseData.children[indices[0]].children[0].children[0].id}
          className="secondary_link"
          onClick={handleOutlineClick}
        >
          {courseData.children[indices[0]].display_name}
        </a>
        <div className="f_a dr">
          <strong className="bold-text-8 dr"></strong>
        </div>
        <a
          href="#"
          id="about"
          className="secondary_link"
          data-id={
            courseData.children[indices[0]].children[indices[1]].children[0].id
          }
          onClick={handleOutlineClick}
        >
          {courseData.children[indices[0]].children[indices[1]].display_name}
        </a>

        <div className="f_a dr">
          <strong className="bold-text-8 dr"></strong>
        </div>
        <a href="#" id="about" className="secondary_link">
          {getDisplayName(courseData, indices)}
        </a>
      </div>
    </div>
  );
};
export default CourseIframeSecondaryMenu;
