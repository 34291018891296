import React, { useContext, useState } from 'react';
import './NotificationBox.css';
import useUserNotification from '../../hooks/useUserNotification';
import {
  AppDataContext,
  useGetGravatarOrUploadedProfileImg,
  IBLSpinLoader,
} from '@iblai/ibl-web-react-common';
import {
  markNotificationAsRead,
  getFilteredNotifications,
} from '../../hooks/utils/notifications';

const NotificationBox = ({setNotificationCount}) => {
  const { appData, setAppData } = useContext(AppDataContext);

  const { profilePic } = useGetGravatarOrUploadedProfileImg(
    appData.userMetaData
  );
  const [onlyShowUnread, setOnlyShowUnread] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [expandedNotification, setExpandedNotification] = useState(null);
  const {
    notifications,
    notificationsLoaded,
    setNotifications,
    setNotificationsLoaded,
  } = useUserNotification();

  const toggleOnlyShowUnread = () => {
    setOnlyShowUnread(!onlyShowUnread);
  };

  const markAllAsRead = async () => {
    const unreadNotifications = notifications.filter(
      (notification) => notification.status === 'UNREAD'
    );
    if (unreadNotifications.length > 0) {
      setNotificationsLoaded(false);
      const markAsReadPromises = unreadNotifications.map(notification =>
        new Promise((resolve) => {
          markNotificationAsRead(
            {
              notification_id: notification.id,
              status: 'READ',
            },
            (err, data, response) => {
              console.log(err, data, response, 'Marking as read:', notification.id);
              resolve();
            }
          );
        })
      );

      try {
        await Promise.all(markAsReadPromises);
        // Update local state
        setNotifications(prevNotifications =>
          prevNotifications.map(notification => ({
            ...notification,
            status: 'READ'
          }))
        );
        setNotificationCount(0)
      } catch (error) {
        console.error('Error marking all notifications as read:', error);
      } finally {
        setNotificationsLoaded(true);
      }
    }
  };

  const markAsRead = (id) => {
    const notification = notifications.find((n) => n.id === id);
    if (notification && notification.status === 'UNREAD') {
      markNotificationAsRead(
        { notification_id: id, status: 'READ' },
        (err, data, response) => {
          // Update local state
          setNotifications(prevNotifications =>
            prevNotifications.map(n =>
              n.id === id ? { ...n, status: 'READ' } : n
            )
          );
          setNotificationCount(prevCount => prevCount - 1);
        }
      );
    }
  };

  const filterNotifications = (days) => {
    const endDate = new Date().toISOString();
    const startDate = new Date(
      Date.now() - days * 24 * 60 * 60 * 1000
    ).toISOString();
    const filterData = {
      end_date: endDate,
      start_date: startDate,
    };

    setNotificationsLoaded(false);
    getFilteredNotifications(filterData, (err, data, response) => {
      if (Array.isArray(response?.body?.results)) {
        setNotifications(response.body.results);
      } else {
        console.error('Error fetching filtered notifications:', err);
      }
      setNotificationsLoaded(true);
    });
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const filteredNotifications = onlyShowUnread
    ? notifications.filter((notification) => notification?.status === 'UNREAD')
    : notifications;

  const getTimeAgo = (createdAt) => {
    const now = new Date();
    const created = new Date(createdAt);
    const diffInMilliseconds = now - created;
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInMinutes < 60) {
      return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hrs ago`;
    } else {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }
  };

  const handleNotificationClick = (notification) => {
    if (notification.status === 'UNREAD') {
      markAsRead(notification.id);
    }
    // commenting this because its not required right now
    // setExpandedNotification(
    //   expandedNotification === notification.id ? null : notification.id
    // );
  };

  return (
    <div
      className="div-block-164 skills-notification-box"
      style={{ display: 'block' }}
    >
      <div className="div-block-165">
        <div className="text-block-104">Notifications</div>
        <div className="w-layout-hflex flex-block-36">
          <div className="text-block-397" style={{ fontSize: '14px' }}>
            Only show unread
          </div>
          <div className="html-embed-25 w-embed">
            <label className="mini-switch">
              <input
                type="checkbox"
                checked={onlyShowUnread}
                onChange={toggleOnlyShowUnread}
              />
              <span className="mini-switch-slider round" />
            </label>
          </div>
        </div>
      </div>
      <div
        className={`div-block-166 ${filteredNotifications.length === 0 ? 'inactive' : 'pop-not'}`}
      >
        {!notificationsLoaded ? (
          <IBLSpinLoader size={25} containerHeight="50px" />
        ) : filteredNotifications.length === 0 ? (
          <div className="text-block-105 no-notification">
            There are no new notifications
          </div>
        ) : (
          filteredNotifications.map((notification) => (
            <React.Fragment key={notification.id}>
              <div
                className={`single-notification-block ${notification?.status === 'READ' ? 'read' : ''}`}
                onClick={() => handleNotificationClick(notification)}
                style={{
                  backgroundColor:
                    expandedNotification === notification?.id
                      ? 'whitesmoke'
                      : 'inherit',
                }}
              >
                <img
                  src={profilePic}
                  loading="lazy"
                  alt=""
                  className="not-img"
                />
                <div className="notification-detail-block">
                  <div className="text-block-373">
                    <span
                      dangerouslySetInnerHTML={{ __html: notification?.title }}
                    />

                    <a
                      href="#"
                      className="not-link"
                      style={{ display: 'none' }}
                    >
                      <span className="product">{notification?.username}</span>
                    </a>
                  </div>
                  <div className="text-block-374">
                    {getTimeAgo(notification?.created_at)}
                  </div>
                </div>
                <img
                  src="/images/x-12.svg"
                  loading="lazy"
                  alt=""
                  className="notification-closer"
                />
                {notification?.status === 'UNREAD' && (
                  <div className="w-layout-hflex unread-status-block" />
                )}
              </div>
              {expandedNotification === notification?.id && (
                <div
                  className="notification-body"
                  style={{
                    padding: '0 20px 6px 20px',
                    marginTop: '10px',
                    wordWrap: 'break-word',
                    width: '100%',
                    textAlign: 'left',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                    backgroundColor: 'white',
                  }}
                  dangerouslySetInnerHTML={{ __html: notification?.body }}
                />
              )}
            </React.Fragment>
          ))
        )}
      </div>
      <div className="w-layout-hflex flex-block-37">
        <div
          data-hover="false"
          data-delay={0}
          className={`skills-notification-date-filter-dropdown-block w-dropdown ${isFilterOpen ? 'w--open' : ''}`}
        >
          <div
            className="skills-notification-date-filter-dropdown-toggle w-dropdown-toggle"
            id="w-dropdown-toggle-0"
            aria-controls="w-dropdown-list-0"
            aria-haspopup="menu"
            aria-expanded={isFilterOpen}
            role="button"
            tabIndex={0}
            style={{ display: 'none' }}
            onClick={toggleFilter}
          >
            <img
              src="/images/calendar-days.svg"
              loading="lazy"
              alt=""
              className="image-229"
            />
            <div
              className="skills-notification-date-filter-dropdown-icon w-icon-dropdown-toggle"
              aria-hidden="true"
            />
            <div className="text-block-399">Filter</div>
          </div>
          <nav
            className={`skills-notification-date-filter-dropdown-list w-dropdown-list ${isFilterOpen ? 'w--open' : ''}`}
            id="w-dropdown-list-0"
            aria-labelledby="w-dropdown-toggle-0"
          >
            <div className="skills-notification-date-filter-form-block w-form">
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                method="get"
                className="skills-notification-date-filter-form"
                data-wf-page-id="6596e21ac435cac3c66baad7"
                data-wf-element-id="3efd6241-e2de-fc47-e011-fe99647febe3"
                aria-label="Email Form 2"
              >
                <label className="skills-notification-date-filter-radio-field w-radio">
                  <input
                    type="radio"
                    data-name="day"
                    id={1}
                    name="day"
                    className="w-form-formradioinput w-radio-input"
                    defaultValue={1}
                    onChange={() => filterNotifications(1)}
                  />
                  <span
                    className="skills-notification-date-filter-radio-fabel w-form-label"
                    htmlFor={1}
                  >
                    Last day
                  </span>
                </label>
                <label className="skills-notification-date-filter-radio-field w-radio">
                  <input
                    type="radio"
                    data-name="day"
                    id={7}
                    name="day"
                    className="w-form-formradioinput w-radio-input"
                    defaultValue={7}
                    onChange={() => filterNotifications(7)}
                  />
                  <span
                    className="skills-notification-date-filter-radio-fabel w-form-label"
                    htmlFor={7}
                  >
                    Last 7 days
                  </span>
                </label>
                <label className="skills-notification-date-filter-radio-field w-radio">
                  <input
                    type="radio"
                    data-name="day"
                    id={15}
                    name="day"
                    className="w-form-formradioinput w-radio-input"
                    defaultValue={15}
                    onChange={() => filterNotifications(15)}
                  />
                  <span
                    className="skills-notification-date-filter-radio-fabel w-form-label"
                    htmlFor={15}
                  >
                    Last 15 days
                  </span>
                </label>
                <label className="skills-notification-date-filter-radio-field w-radio">
                  <input
                    type="radio"
                    data-name="day"
                    id={30}
                    name="day"
                    className="w-form-formradioinput w-radio-input"
                    defaultValue={30}
                    onChange={() => filterNotifications(30)}
                  />
                  <span
                    className="skills-notification-date-filter-radio-fabel w-form-label"
                    htmlFor={30}
                  >
                    Last 30 days
                  </span>
                </label>
              </form>
              <div
                className="w-form-done"
                tabIndex={-1}
                role="region"
                aria-label="Email Form 2 success"
              >
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div
                className="w-form-fail"
                tabIndex={-1}
                role="region"
                aria-label="Email Form 2 failure"
              >
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </nav>
        </div>
        <a
          href="#"
          className="notification-mark-as-read-btn w-inline-block"
          onClick={markAllAsRead}
        >
          <img
            src="/images/check-check.svg"
            loading="lazy"
            alt=""
            className="image-228"
          />
          <h5 className="heading-11" style={{ fontSize: '14px' }}>
            Mark all as read
          </h5>
        </a>
      </div>
    </div>
  );
};

export default NotificationBox;
