import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import './CourseIframeContentView.css';
import { getCourseTabs } from '../../../hooks/utils/iframe';

const CourseIframeContentView = () => {
  const { courseID } = useParams();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const handleTabs = (tabsData) => {
      setTabs(tabsData);
    };

    getCourseTabs(courseID, handleTabs);
  }, [courseID]);

  return (
    <>
      <div className="div-block-630" style={{ marginTop: '76px' }}>
        <NavLink
          to={`/courses/${courseID}/course`}
          className={({ isActive, isPending }) =>
            `link-options-3 ${isPending ? 'pending' : isActive ? 'active' : ''}`
          }
        >
          Course
        </NavLink>

        {tabs?.map((tab) => {
          if (tab.title === 'Course' || tab.title === 'Instructor') return null;
          return (
            <NavLink
              key={tab.tab_id}
              to={`/courses/${courseID}/${tab.tab_id}`}
              className={({ isActive, isPending }) =>
                `link-options-3 ${isPending ? 'pending' : isActive ? 'active' : ''}`
              }
            >
              {tab.title}
            </NavLink>
          );
        })}
      </div>
      <Outlet />
    </>
  );
};

export default CourseIframeContentView;
