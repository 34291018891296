import React, { useState } from 'react';
import useCourseDetail from '../../../hooks/useCourseDetail';
import '../../../css/course.css';
import { getRandomImage } from '../../../hooks/helpers';
import IblSpinLoader from '../../../loaders/iblSpinLoader';
import './CourseDetailViewContent.css';
import CourseHeader from '../../../components/CourseAbout/CourseHeader/CourseHeader';
import CourseEnrollmentButton from '../../../components/CourseAbout/CourseEnrollmentButton/CourseEnrollmentButton';
import CourseOptions from '../../../components/CourseAbout/CourseOptions/CourseOptions';
import CourseAbout from '../../../components/CourseAbout/CourseAbout';
import CourseSyllabus from '../../../components/CourseAbout/CourseSyllabus/CourseSyllabus';
import CourseInfo from '../../../components/CourseAbout/CourseInfo/CourseInfo';
import {
  GLOBAL_COLORS,
  NoItemView,
  useCourseIframe,
} from '@iblai/ibl-web-react-common';
import { FaTools } from 'react-icons/fa';
import { t } from 'i18next';

const CourseDetailViewContent = ({ courseID }) => {
  const [
    courseInfo,
    courseInfoLoaded,
    courseDetails,
    courseDetailsLoaded,
    enrollmentStatus,
    enrollmentStatusLoaded,
    noCourseFound,
  ] = useCourseDetail(courseID);
  const [activeOption, setActiveOption] = useState('about');
  const [isContentVisible, setIsContentVisible] = useState(false);

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  const handleShowAllClick = () => {
    setIsContentVisible(!isContentVisible);
  };

  const handleImageError = (e) => {
    e.target.src = getRandomImage();
  };

  const imageUrl =
    process.env.REACT_APP_IBL_LMS_URL + courseInfo?.course_image_asset_path;

  return (
    <>
      {!courseInfoLoaded ? (
        <IblSpinLoader size={40} containerHeight={'400px'} />
      ) : noCourseFound ? (
        <div className="about-body-2">
          <div
            className="about-cont-2"
            style={{ backgroundColor: 'white', height: '100vh' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                marginRight: '3%',
                marginLeft: '3%',
              }}
            >
              <NoItemView
                displayMessage={t(
                  "Hey, sorry about that! It seems you either don't have access to the course, or the course does not exist."
                )}
                imageUrl="/images/tools.png"
                displayPadding={true}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="about-body-2">
          <div className="about-cont-2">
            <div className="about-grid">
              {courseInfoLoaded && (
                <CourseHeader
                  courseInfo={courseInfo}
                  enrollmentStatus={enrollmentStatus}
                  enrollmentStatusLoaded={enrollmentStatusLoaded}
                  imageUrl={imageUrl}
                  handleImageError={handleImageError}
                  courseID={courseID}
                >
                  <CourseOptions
                    activeOption={activeOption}
                    handleOptionClick={handleOptionClick}
                  >
                    <div className="panels-3">
                      {activeOption === 'about' && (
                        <CourseAbout
                          courseInfo={courseInfo}
                          isContentVisible={isContentVisible}
                          handleShowAllClick={handleShowAllClick}
                        />
                      )}
                      {activeOption === 'syllabus' && (
                        <CourseSyllabus
                          courseDetails={courseDetails}
                          courseLoaded={courseDetailsLoaded}
                          courseID={courseID}
                          enrollmentStatus={enrollmentStatus}
                        />
                      )}
                    </div>
                  </CourseOptions>
                </CourseHeader>
              )}

              <div className="right-col-3">
                <div className="about-box-2">
                  <div className="div-block-536">
                    <div className="vid-header-3 w-clearfix">
                      <img
                        src={imageUrl}
                        onError={handleImageError}
                        alt="course image"
                        className="video w-video w-embed c-image"
                      />

                      {enrollmentStatusLoaded && (
                        <CourseEnrollmentButton
                          enrollmentStatus={enrollmentStatus}
                          coursePrice={courseInfo.course_price}
                          courseID={courseID}
                          courseName={courseInfo?.display_name}
                        />
                      )}
                    </div>

                    {courseInfoLoaded && <CourseInfo courseInfo={courseInfo} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseDetailViewContent;
