import { useContext } from 'react';
import { IframeContext } from '../context/iframe-context';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

export const CourseUnitIframe = () => {
  const {
    edxIframe,
    edxIframeLoading,
    isCourseContentAvailable,
    setEdxIframeLoading,
  } = useContext(IframeContext);
  return (
    <div className="html-embed-19 w-embed w-iframe">
      {edxIframeLoading && isCourseContentAvailable && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <IBLSpinLoader />
        </div>
      )}
      {isCourseContentAvailable ? (
        <iframe
          id="edx-iframe"
          title="Course InnerWare"
          sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads"
          frameBorder={0}
          src={edxIframe}
          style={{
            width: '100%',
            height: 'calc(100vh - 100px - 62px)',
          }}
          allowFullScreen
          onLoad={() => setEdxIframeLoading(false)}
        ></iframe>
      ) : (
        <>
          <div style={{ textAlign: 'center' }}>There is no content here</div>
        </>
      )}
    </div>
  );
};
