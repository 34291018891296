import React from 'react';
import {
  getIframeURL,
  getNextUnitIframe,
  getPreviousUnitIframe,
} from '../hooks/utils/iframe';
import { getParameterByName } from '../hooks/helpers';
import { useParams } from 'react-router-dom';

const CourseIframeSequenceMenu = ({
  courseData,
  setEdxIframe,
  changeIframeLoadingStatus,
  setUnitIdFromUrl,
}) => {
  const { courseID } = useParams();
  // TODO : when not unit id is available in url, get the first unit id and get the next iframe url from that,instead of setting
  //  the previous and next buttons to null
  const handleIframeUrl = (iframeURL) => {
    setEdxIframe(iframeURL);
  };

  const handlePreviousBtnClick = () => {
    const previousUnitBlock = getPreviousUnitIframe(
      getParameterByName('unit_id'),
      courseData
    );
    if (previousUnitBlock !== null) {
      const newURL = `/courses/course?course_id=${getParameterByName('course_id')}&unit_id=${previousUnitBlock}`;
      changeIframeLoadingStatus(true);
      window.history.pushState(null, 'Course', newURL);
      setUnitIdFromUrl(previousUnitBlock);
      getIframeURL(courseID, courseData, handleIframeUrl);
    }
  };

  const handleNextBtnClick = () => {
    const nextUnitBlock = getNextUnitIframe(
      getParameterByName('unit_id'),
      courseData
    );
    if (nextUnitBlock !== null) {
      const newURL = `/courses/course?course_id=${getParameterByName('course_id')}&unit_id=${nextUnitBlock}`;
      changeIframeLoadingStatus(true);
      window.history.pushState(null, 'Course', newURL);
      setUnitIdFromUrl(nextUnitBlock);
      getIframeURL(courseID, courseData, handleIframeUrl);
    }
  };

  return <></>;
};

export default CourseIframeSequenceMenu;
