import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  createCheckoutSession,
  enrollToCourse,
  getAllStripeProducts,
} from '../../../hooks/utils/courseDetail';
import { useTranslation } from 'react-i18next';

const CourseEnrollmentButton = ({
  enrollmentStatus,
  courseID,
  courseName,
  coursePrice,
}) => {
  const [buttonContent, setButtonContent] = useState(null); // State to hold button content
  const { t } = useTranslation();

  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const productData = await getAllStripeProducts(courseName, courseID);
      if (productData?.price_id) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            onClick={() =>
              createCheckoutSession(productData?.price_id, productData?.mode)
            }
          >
            {t('Buy Now')}
          </Link>
        );
      } else {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to="#"
            onClick={handleEnrollmentBtnClick}
          >
            {t('Enroll')}
          </Link>
        );
      }
    } catch (error) {
      console.error('Error fetching Stripe products:', error);
      setButtonContent(null); // Set button content to null in case of error
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_IBL_ENABLE_STRIPE === 'true') {
      fetchData();
    } else {
      setButtonContent(
        <Link
          className="button-5 w-button"
          to="#"
          onClick={handleEnrollmentBtnClick}
        >
          Enroll
        </Link>
      );
    }
    return () => {};
  }, [courseName, courseID]); // Dependencies for useEffect

  const handleEnrollmentBtnClick = () => {
    enrollToCourse(enrollToCourseCallback, courseID);
  };

  const enrollToCourseCallback = (data) => {
    if (data && data.created) {
      navigate(`/courses/${courseID}/course`);
    } else {
      alert(t('Course Enrollment Failed, Something went wrong'));
    }
  };

  const isEnrolled = enrollmentStatus.is_enrolled;
  const invitationOnly = enrollmentStatus.invitation_only;

  if (isEnrolled) {
    return (
      <Link className="button-5 w-button" to={`/courses/${courseID}/course`}>
        {t('Access Course')}
      </Link>
    );
  } else if (invitationOnly) {
    return (
      <a className="button-5 w-button disabled" href="#">
        {t('Invitation Only')}
      </a>
    );
  } else if (coursePrice && coursePrice !== 0 && coursePrice !== 'Free') {
    return (
      <Link
        className="button-5 w-button"
        onClick={() => createCheckoutSession(courseID)}
      >
        {t('Buy Now')}
      </Link>
    );
  } else {
    return (
      <Link
        className="button-5 w-button"
        to="#"
        onClick={handleEnrollmentBtnClick}
      >
        {t('Enroll')}
      </Link>
    );
  }
};

export default CourseEnrollmentButton;
