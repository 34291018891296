import React from 'react';

const CourseOptions = ({ activeOption, handleOptionClick, children }) => (
  <div
    className="about-box-2 mt20"
    id="w-node-adb8c6bd-8ff9-86ea-d38f-f1efe54814ac-c66baadf"
  >
    <div className="options-3">
      <div className="div-block-530">
        <div
          className={`link-options-2 ${activeOption === 'about' ? 'active' : ''}`}
          data-name="about"
          onClick={() => handleOptionClick('about')}
        >
          About
        </div>
        <div
          className="link-options-2"
          data-name="instructors"
          style={{ display: 'none' }}
        >
          Instructors
        </div>
        <div
          className={`link-options-2 ${activeOption === 'syllabus' ? 'active' : ''}`}
          onClick={() => handleOptionClick('syllabus')}
        >
          Syllabus
        </div>
      </div>
    </div>

    {children}
  </div>
);

export default CourseOptions;
