import './ResumeUpload.css';
import {
  api,
  getTenant,
  getUserId,
  getUserName,
  IBLSpinLoader,
} from '@iblai/ibl-web-react-common';
import { useRef, useState } from 'react';

const ResumeUpload = ({
  setResumeUploaded,
  setErrorMessage,
  setSelectedFile,
  selectedFile,
  errorMessage,
  resumeUploaded,
}) => {
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 5242880) {
      // 5MB in bytes
      window.alert('File size should be less than 25MB');
    } else if (file && !['application/pdf'].includes(file.type)) {
      window.alert('Invalid file type. Please upload a PDF.');
    } else {
      handleSubmit(file);
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFileChange({ target: { files: [file] } });
  };

  const handleSubmit = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('resume', file);
      formData.append('user', getUserName());
      formData.append('platform', getTenant());

      setResumeUploaded(false);
      setErrorMessage('');
      setIsUploading(true);

      api.ibldmplatform.uploadResume(
        { org: getTenant(), userID: getUserName() },
        formData,
        (data) => {
          setResumeUploaded(true);
          setSelectedFile(file);
          setErrorMessage('');
          setIsUploading(false);
        },
        (error) => {
          setResumeUploaded(true);
          setIsUploading(false);

          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          if (error?.status === 400) {
            setErrorMessage('User resume with this user already exists');
            return;
          }
          window.alert('Error uploading resume. Please try again later.');
          console.error(error);
        }
      );
    }
  };

  return (
    <>
      <div className="div-block-78 res">
        <div className="div-block-84 level_rate">
          <div className="text-block-62 res">Upload Your Resume (Optional)</div>
          <div className="text-block-147">
            Our AI Engine will detect and add your skills to your learning
            profile
          </div>

          {isUploading ? (
            <div style={{ marginTop: '10px' }}>
              <IBLSpinLoader size={40} />
            </div>
          ) : (
            <div
              className="div-block-233"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
              onDrop={handleFileDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="div-block-234 div-block-234-custom">
                <div className="div-block-235 div-block-235-custom">
                  <img
                    src="/images/upload.png"
                    loading="lazy"
                    alt=""
                    className="image-93"
                  />
                </div>
                <div>
                  <div className="text-block-148 text-block-148-custom">
                    Drag and drop your resume here
                  </div>
                  <div
                    className="text-block-149"
                    style={{ textAlign: 'center' }}
                  >
                    (PDF up to 25MB)
                  </div>

                  <>
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      id="fileInput"
                    />
                  </>
                </div>
              </div>

              {selectedFile && (
                <div
                  className="file-name"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10px',
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-file-text"
                    style={{ marginRight: '8px' }}
                  >
                    <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
                    <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                    <path d="M10 9H8" />
                    <path d="M16 13H8" />
                    <path d="M16 17H8" />
                  </svg>
                  <span>{selectedFile.name}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResumeUpload;
