import { ColorRing } from 'react-loader-spinner';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCourseIframe } from '@iblai/ibl-web-react-common';
import './CourseIframeCourseTabView.css';
import { getIframeURL } from '../../../hooks/utils/iframe';

const CourseIframeCourseTabView = ({ tab }) => {
  console.log('tab ', tab);
  const { courseID } = useParams();
  const [courseDetails, courseLoaded] = useCourseIframe(courseID);
  const [edxIframe, setEdxIframe] = useState('');
  const [edxIframeLoading, setEdxIframeLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (tab.replace(/\/$/, '') === 'bookmarks') {
      handleIframeUrl(
        `${process.env.REACT_APP_IBL_LMS_URL}/courses/${courseID}/bookmarks/`
      );
    } else if (tab.startsWith('static_tab_')) {
      handleIframeUrl(
        `${process.env.REACT_APP_IBL_LMS_URL}/courses/${courseID}/${tab.replace('static_tab_', '')}`
      );
    } else {
      getIframeURL(courseID, tab, handleIframeUrl);
    }
  }, [courseLoaded]);
  const handleIframeUrl = (iframeURL) => {
    setEdxIframe(iframeURL);
  };

  const changeIframeLoadingStatus = (status) => {
    setEdxIframeLoading(status);
  };

  const handleCollapseClick = (status) => {
    setIsExpanded(status);
  };
  return courseLoaded ? (
    courseDetails && courseDetails.children ? (
      <>
        <div
          className={`inside_page in_tab`}
          style={{ marginLeft: isExpanded ? '0' : '0', paddingTop: '100px' }}
        >
          <div className="html-embed-19 w-embed w-iframe">
            {edxIframeLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={[
                    '#4a78b8',
                    '#6cafe1',
                    '#4a78b8',
                    '#6cafe1',
                    '#4a78b8',
                    '#6cafe1',
                    '#4a78b8',
                    '#6cafe1',
                    '#4a78b8',
                    '#6cafe1',
                  ]}
                />
              </div>
            )}
            <iframe
              id="edx-iframe"
              title="Forum InnerWare"
              sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads"
              frameBorder={0}
              src={edxIframe}
              style={{ width: '100%', height: 'calc(100vh - 100px - 62px)' }}
              allowFullScreen=""
              onLoad={() => setEdxIframeLoading(false)}
            ></iframe>
          </div>
        </div>
      </>
    ) : (
      <>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\n.no-course-data-box {\n    margin-top: 24px;\n    padding: 24px;\n    border-width: 1px;\n    border-style: solid;\n    border-color: rgb(221, 225, 230);\n    border-radius: 4px;\n    height: 238px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    margin-left: 24px;\n    margin-right: 24px;\n}\n\n.no-course-flex img {\n    display: block;\n    margin: 0 auto;\n    margin-bottom: 16px;\n}\n\n',
          }}
        />

        <div className="no-course-data-cont">
          <div className="no-course-data-box">
            <div className="no-course-flex">
              <img
                style={{ width: 40, height: 40 }}
                src="/images/open-book.png"
                alt=""
              />
              <div>No content for this course yet.</div>
            </div>
          </div>
        </div>
      </>
    )
  ) : (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass=""
        colors={[
          '#4a78b8',
          '#6cafe1',
          '#4a78b8',
          '#6cafe1',
          '#4a78b8',
          '#6cafe1',
          '#4a78b8',
          '#6cafe1',
          '#4a78b8',
          '#6cafe1',
        ]}
      />
    </div>
  );
};

export default CourseIframeCourseTabView;
