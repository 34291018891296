import { useEffect,  useState } from 'react';
import { getNotifications } from './utils/notifications';

function useUserNotification() {
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoaded, setNotificationsLoaded] = useState(false);


  useEffect(() => {
   
      getNotifications(
        (data) => {
          setNotifications(data);
          setNotificationsLoaded(true);
        },
        1,
        [],
        ''
      );
   
  }, []);

  return { notifications, notificationsLoaded, setNotifications, setNotificationsLoaded };
}

export default useUserNotification;
