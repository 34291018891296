import React from 'react';
import { secondsToHoursAndMinutes } from '../../../hooks/helpers';
import { formatReadableDate } from '@iblai/ibl-web-react-common';

const CourseInfo = ({ courseInfo }) => {
  const getCourseDuration = (duration) => {
    let time;
    try {
      time = secondsToHoursAndMinutes(duration);
      if (time === 'NaN:NaN') {
        time = duration;
      }
    } catch (e) {
      time = duration;
    }

    return time;
  };
  return (
    <>
      <div className="div-block-537">
        {courseInfo?.course_price !== '' && (
          <div className="div-block-535">
            <img
              alt=""
              className="skills-about-icon"
              src="/images/coin_1coin.png"
            />
            <div className="text-block-311">{courseInfo?.course_price}</div>
          </div>
        )}

        <div className="div-block-535">
          <img
            alt=""
            className="skills-about-icon"
            src="/images/language_1language.png"
          />
          <div className="text-block-311">{courseInfo?.language}</div>
        </div>
        <div className="div-block-535">
          <img
            alt=""
            className="skills-about-icon"
            src="/images/certificate_1certificate.png"
          />
          <div className="text-block-311">Certificate</div>
        </div>
        {courseInfo?.duration !== '' && (
          <div className="div-block-535">
            <img
              alt=""
              className="skills-about-icon"
              src="/images/clock_3clock.png"
            />
            <div className="text-block-311">
              {getCourseDuration(courseInfo?.duration)}
            </div>
          </div>
        )}

        {courseInfo?.start_date !== '' && (
          <div className="div-block-535">
            <img
              alt=""
              className="skills-about-icon"
              src="/images/date_1date.png"
            />
            <div className="text-block-311">
              {formatReadableDate(courseInfo?.start_date)}
            </div>
          </div>
        )}

        <div className="div-block-535" style={{ display: 'none' }}>
          <img
            alt=""
            className="skills-about-icon"
            src="images/63b5a1e8664d9617e9b5018f_level_icon_163b5a1e8664d9617e9b5018f_level_icon.png"
          />
          <div className="text-block-311">Beginner</div>
        </div>

        <div className="div-block-535" style={{ display: 'none' }}>
          <img alt="" className="skills-about-icon" src="images/cc_1cc.png" />
          <div className="text-block-311">
            Arabic, French, Portuguese, Italian, German, Russian, English,
            Spanish, Korean, Japanese
          </div>
        </div>
        <div className="div-block-535 last" style={{ display: 'none' }}>
          <div className="div-block-527">
            <img
              alt=""
              className="skills-about-icon"
              src="images/share_3share.png"
            />
            <div className="text-block-311">Share this course</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseInfo;
