import { useEffect, useRef, useState } from 'react';
import {
  getCourseDetails,
  getCourseInfo,
  getEnrollmentStatus,
} from './utils/courseDetail';

function useCourseDetail(courseID) {
  const [courseInfo, setCourseInfo] = useState({});
  const [courseInfoLoaded, setCourseInfoLoaded] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  const [courseDetailsLoaded, setCourseDetailsLoaded] = useState(false);
  const [enrollmentStatus, setEnrollmentStatus] = useState({});
  const [enrollmentStatusLoaded, setEnrollmentStatusLoaded] = useState(false);
  const [noCourseFound, setNoCourseFound] = useState(false);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      getCourseInfo(courseID, courseInfoCallback, courseInfoErrorCallback);
      if (!localStorage.getItem('axd_token')) {
        EnrollmentStatusCallback({
          can_enroll: true,
          invitation_only: false,
          is_admin: true,
          is_enrolled: true,
        });
        courseDetailsCallback({});
      } else {
        getEnrollmentStatus(courseID, EnrollmentStatusCallback);
        getCourseDetails(courseID, courseDetailsCallback);
      }
      hasFetchedData.current = true;
    }
  }, []);

  const courseDetailsCallback = (data) => {
    setCourseDetails(data);
    setCourseDetailsLoaded(true);
  };
  const courseInfoCallback = (data) => {
    setCourseInfo(data);
    setCourseInfoLoaded(true);
  };

  const courseInfoErrorCallback = (error) => {
    setNoCourseFound(true);
    setCourseInfoLoaded(true);
  };

  const EnrollmentStatusCallback = (data) => {
    setEnrollmentStatus(data);
    setEnrollmentStatusLoaded(true);
  };

  return [
    courseInfo,
    courseInfoLoaded,
    courseDetails,
    setCourseDetails,
    enrollmentStatus,
    enrollmentStatusLoaded,
    noCourseFound,
  ];
}

export default useCourseDetail;
