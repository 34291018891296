import React from 'react';
import { processFilterData } from '../../hooks/helpers';
import './FilterTags.css';

const FilterTags = ({
  selectedTerms,
  setSelectedTerms,
  facetHandler,
  setDiscoverLoaded,
}) => {
  const processedData = processFilterData(selectedTerms);

  const handleTagClose = (facetName, term) => {
    const processedTerm = `${facetName}:${term}`;

    setSelectedTerms((prevSelectedTerms) => {
      const newSelectedTerms = prevSelectedTerms.filter(
        (selectedTerm) => selectedTerm !== processedTerm
      );

      setDiscoverLoaded(false);
      facetHandler(newSelectedTerms);

      return newSelectedTerms;
    });
  };

  return (
    <div>
      <div className="w-layout-hflex filter-summary-block">
        {Object.keys(processedData).map((key) => (
          <div
            className="w-layout-hflex filter-summary-box"
            data-name={key}
            key={key}
          >
            <div className="filter-tag-title">{key}:</div>
            <div className="filter-tag-content">
              {processedData[key].map((term, index) => (
                <span key={index} className="filter-term">
                  {term}
                  <img
                    alt=""
                    onClick={() => handleTagClose(key, term)}
                    className="filter-tag-closer-icon"
                    loading="lazy"
                    src="/images/x-1.svg"
                  />
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterTags;
