import React, { useRef } from 'react';
import './Statengagementbox.css';
import EngagementChart from '../../views/EngagementChart';
import { useTranslation } from 'react-i18next';

const Statengagementbox = (props) => {
  const { t } = useTranslation();
  const tooltipRef = useRef(null);
  return (
    <div className="div-block-33 sp">
      <div className="div-block-13 l-h h w100 ovh">
        <div className="div-block-14">
          <div className="skm">{t('Engagement')}</div>
        </div>
        <div className="html-embed-2 w-embed w-script">
          <link
            defer=""
            href="https://cdn.jsdelivr.net/gh/sppantg/cont-graph@main/css/github_contribution_graph.css"
            media="all"
            rel="stylesheet"
          />
          <style
            dangerouslySetInnerHTML={{
              __html: `
	.af-view .wday, .af-view .month{
  fill: #6d717f !important;
	}
  .af-view .gcont{
  	width: 816px;
    padding-left: 16px;
  }
@media only screen and (max-width: 1269px) {
  .af-view .gcont{
    overflow: auto;
  }
}
 `,
            }}
          />
          <div className="gcont">
            <EngagementChart
              data={props?.engagementData}
              tooltipRef={tooltipRef}
            />
            <div
              ref={tooltipRef}
              className="svg-tip"
              style={{ display: 'none' }}
            ></div>

            <div className="width-full f6 px-0 px-md-5 py-1">
              <div className="float-right color-fg-muted" title="">
                Less
                <svg className="d-inline-block" height={10} width={10}>
                  <rect
                    className="ContributionCalendar-day"
                    data-level={0}
                    fill="#E2F1FB"
                    height={10}
                    rx={2}
                    ry={2}
                    width={10}
                  ></rect>
                </svg>
                <svg className="d-inline-block" height={10} width={10}>
                  <rect
                    className="ContributionCalendar-day"
                    data-level={1}
                    fill="#AAD3F0"
                    height={10}
                    rx={2}
                    ry={2}
                    width={10}
                  ></rect>
                </svg>
                <svg className="d-inline-block" height={10} width={10}>
                  <rect
                    className="ContributionCalendar-day"
                    data-level={2}
                    fill="#93C4E7"
                    height={10}
                    rx={2}
                    ry={2}
                    width={10}
                  ></rect>
                </svg>
                <svg className="d-inline-block" height={10} width={10}>
                  <rect
                    className="ContributionCalendar-day"
                    data-level={3}
                    fill="#81BBDF"
                    height={10}
                    rx={2}
                    ry={2}
                    width={10}
                  ></rect>
                </svg>
                <svg className="d-inline-block" height={10} width={10}>
                  <rect
                    className="ContributionCalendar-day"
                    data-level={4}
                    fill="#629ABC"
                    height={10}
                    rx={2}
                    ry={2}
                    width={10}
                  ></rect>
                </svg>
                More
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statengagementbox;
