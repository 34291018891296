import { Outlet, useLocation } from 'react-router-dom';
import {
  AppDataContext,
  Copilot,
  useDashboard,
} from '@iblai/ibl-web-react-common';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MobileHeader from '../../components/Header/MobileHeader/MobileHeader';
import './StaticView.css';
import React from 'react';


const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const StaticView = ({path="index"}) => {
  const originURL = new URL(window.location.href).origin
  return (
    <span>
      <span className="af-view">
        <div className="body">
          <div className="full">
            <div
              className="main-content"
              style={{paddingRight:"unset"}}
            >
              <div className="div-block-326">
                <Header></Header>
                <span dangerouslySetInnerHTML={{
                  __html:`<iframe src="${originURL}/${process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_IFRAME_HTML_SUB_FOLDER_PATH}/${path}.html" width="100%" height="100vh" style="height: 100vh;margin-top: 77px; border:unset;"></iframe>`
                }}></span>
                <Footer></Footer>
              </div>
            </div>
          </div>
        </div>
      </span>
    </span>
  );
};

export default StaticView;
