import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import './Coursesbox.css';
import { EmptyBox, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import Courseslider from '../Courseslider';

const Coursesbox = ({ userCourseData, userCourseDataLoaded }) => {
  const isMobile = useMediaQuery({ maxWidth: 579 });
  const isMedium = useMediaQuery({ minWidth: 580, maxWidth: 1024 });

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: isMobile
      ? 1
      : isMedium
        ? 2
        : userCourseData?.length >= 4
          ? 4
          : userCourseData?.length,
    slidesToScroll: 1,
    vertical: false,
    centerMode: false,
    centerPadding: '0',
    arrows: false,
  };

  const sliderRef = useRef(null);

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current?.slickPrev();
  };

  return (
    <div className="courses_res sl_c">
      <div className="flex home_path">
        {!userCourseDataLoaded ? (
          <IBLSpinLoader size={40} />
        ) : Array.isArray(userCourseData) && userCourseData?.length > 0 ? (
          <Courseslider
            courses={userCourseData}
            handlePrev={handlePrev}
            handleNext={handleNext}
            sliderRef={sliderRef}
            sliderSettings={sliderSettings}
          />
        ) : (
          <EmptyBox />
        )}
      </div>
    </div>
  );
};

export default Coursesbox;
