import React from 'react';
import './Credentialsbox.css';
import { Link } from 'react-router-dom';
import NoItemView from '../NoItemView';
import { GLOBAL_COLORS, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import Credentialbox from '../Credentialbox/Credentialbox';
import { FaPlus } from 'react-icons/fa6';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Credentialsbox = ({
  credentialsData,
  userCredentialsDataLoaded,
  appData,
}) => {
  const hasCredentials = credentialsData?.length > 0;
  const { t } = useTranslation();
  return (
    <div className="prof_box">
      <div className={'d-flex flex-align-center flex-justify-space-between'}>
        <div className="text-block sec_title">{t('Credentials')}</div>
        <Link className="link-block-5 w-inline-block" to="/profile/credentials">
          <FaPlus className="image-15" color={GLOBAL_COLORS.icon_color} />
        </Link>
      </div>

      {!userCredentialsDataLoaded ? (
        <IBLSpinLoader size={40} containerHeight="100px" />
      ) : hasCredentials ? (
        <div className="div-block-19 cert-grid">
          {credentialsData?.map((credential) => (
            <Credentialbox
              key={credential?.credentialDetails?.entityId}
              credential={credential}
            />
          ))}
        </div>
      ) : (
        <NoItemView
          imageUrl="images/trophy.png"
          displayMessage={t("No credentials yet") + '.'}
          displayPadding={false}
        />
      )}
      <div className="div-block-18 d-none">
        <Link className="link-block-4 w-inline-block" to="/profile/credentials">
          <div className="text-block-13">{t('Manage Credentials')}</div>
          <img
            alt=""
            className="image-15 rc"
            loading="lazy"
            src="images/chevron_1chevron.png"
          />
        </Link>
      </div>
    </div>
  );
};

export default Credentialsbox;
