import React, { useEffect, useState, useCallback } from 'react';
import { getRoles, rolesSearchAPI, slugify } from '../../hooks/helpers';
import ReactPaginate from 'react-paginate';
import './RoleSelection.css';
import { IBLSpinLoader, isJSON } from '@iblai/ibl-web-react-common';
import { debounce } from 'lodash';

const RoleSelection = ({
  selectedRoles,
  setSelectedRoles,
  searchedRoles,
  setSearchedRoles,
  rolesLoaded,
  setRolesLoaded, addNewRole
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const roleCallBack = (data) => {
    if (data?.results) {
      setSearchedRoles(data);
      return;
    }
    setSelectedRoles({
      results: [],
      num_pages: 1,
    });
  };

  const getRoleDescription = (role) => {
    if (isJSON(role._source.data)) {
      return JSON.parse(role._source.data).description;
    }
  };

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
    getRoles(roleCallBack, selected + 1, [], searchTerm);
  };

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      setShowRoleNotFoundUI(searchValue.length > 1)
      getRoles(roleCallBack, 1, [], searchValue);
    }, 200),
    []
  );

  const handleInputChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleRoleToggle = (role) => {
    setSelectedRoles((prevSelectedRoles) =>
      prevSelectedRoles.some((r) => r._source.role_id === role._source.role_id)
        ? prevSelectedRoles.filter(
            (r) => r._source.role_id !== role._source.role_id
          )
        : [...prevSelectedRoles, role]
    );
  };

  const mergeSelectedAndSearchedRoles = () => {
    const selectedRoleIds = selectedRoles.map((role) => role._source.role_id);
    const mergedRoles = [
      ...selectedRoles,
      ...searchedRoles.results.filter(
        (role) => !selectedRoleIds.includes(role._source.role_id)
      ),
    ];
    return mergedRoles;
  };

  const mergedRoles = mergeSelectedAndSearchedRoles();



  //ADD ROLES
  const defaultFields = {
    title:"",
    description:""
  }
  const [addRoleModalFocused, setAddRoleModalFocused] = useState(false);
  const [showRoleNotFoundUI, setShowRoleNotFoundUI] = useState(false)
  const [fields, setFields] = useState(defaultFields)

  const handleCloseAddRole = () => {
    setSubmittingRole(false)
    setFields(defaultFields)
    setNotification({})
    setAddRoleModalFocused(false)
  }

  const [notification, setNotification] = useState({
    success:null,
    msg:null
  })
  const handleSetNotification = (success, msg, callback=()=>{}) => {
    setNotification({
      msg: msg,
      success
    })
    setTimeout(()=>{
      setNotification({})
      callback()
    }, 2500)
  }

  const [submittingRole, setSubmittingRole] = useState(false)

  const handleAddRoleInputChange = (e) => {
    setFields({
      ...fields,
      [e?.target?.name]:e?.target?.value
    })
  }

  const handleAddRoleSubmission = () => {
    setNotification({})
    if(!fields.title){
      return
    }
    const roleSlug = `${slugify(fields.title)}-${Date.now()}`
    setSubmittingRole(true)
    const roleData = {
      name:fields.title,
      slug:roleSlug,
      ...(fields.description ? {
        data:{
          description:fields.description
        }
      } : {})

    }
    addNewRole(roleData, ()=>{
      //success
      const updatedRoleData = {
        _source:{
          name:fields.title,
          slug:roleSlug,
          role_id:roleSlug,
          data:{
            description: fields.description
          }
        }
      }
      setSelectedRoles((old)=>[
        updatedRoleData,
        ...old
      ])
      setSearchedRoles({
        ...searchedRoles,
        results:[
          updatedRoleData,
          ...searchedRoles?.results
        ]
      })
      handleSetNotification(true, "Role created successfully.", ()=>{
        handleCloseAddRole()
      })
      setSubmittingRole(false)
    }, ()=>{
      //error
      handleSetNotification(false, "An error occurred. Try again!")
      setSubmittingRole(false)
    })
  }

  return (
    <>
      <div className="div-block-78">
        <div className="div-block-84 role">
          <div className="text-block-62">Select Your Role</div>
          <div className="form-block welcome w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get"
                  className="form welcome_f v-flex"
                  data-wf-page-id="6596e21ac435cac3c66baaef" data-wf-element-id="70b076f3-9681-eba1-cb85-a403ca22711d"
                  onSubmit={(e) => e.preventDefault()}
            >
              <div className="w-layout-hflex flex-block-63">
                <div className="div-block"><img src="/images/magnifying-glass_1magnifying-glass.png" loading="lazy"
                                                alt=""
                                                className="image-2" /></div>
                <input className="text-field np role-input-search w-input"
                       value={searchTerm}
                       onChange={(e) => {
                         handleInputChange(e);
                       }}
                       maxLength={256}
                       name="email-6"
                       data-name="Email 6" placeholder="Search for a Role" id="email-6" required="" />
              </div>
              {
                showRoleNotFoundUI && <div className="w-layout-hflex role-not-found-block" style={{ display: "flex" }}>
                  <p className="paragraph-2">Can&#x27;t find it ? <a href="#" onClick={() => setAddRoleModalFocused(true)}
                                                                     data-w-id="7928874e-8d9b-c71b-91ff-ece7a82efeaa"
                                                                     className="role-select-add">Add it now</a>
                  </p>
                </div>
              }
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
          <div className="sug_roles">
            <div className="tx_s" style={{ marginBottom: '36px' }}>
              {selectedRoles.length} Selected Roles
            </div>
            <div className="div-block-232">
              {mergedRoles.map((role) => {
                const isSelected = selectedRoles.some(
                  (r) => r._source.role_id === role._source.role_id
                );
                return (
                  <div
                    key={role._source.role_id}
                    className={`role_div ${isSelected ? 'selected' : ''}`}
                    onClick={() => handleRoleToggle(role)}
                  >
                    {isSelected && (
                      <img
                        src="/images/checked_1checked.png"
                        loading="lazy"
                        alt=""
                        className="image-48"
                      />
                    )}
                    <div className="text-block-145">{role._source.name}</div>
                    <div className="text-block-146">
                      {getRoleDescription(role)}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="w-layout-hflex pagination-block m-top">
              <div className="w-layout-hflex pagination-block-container">
                {searchedRoles.num_pages > 1 && (
                  <ReactPaginate
                    onPageChange={paginate}
                    pageCount={searchedRoles.num_pages}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    containerClassName={
                      'w-layout-hflex pagination-block-container'
                    }
                    pageLinkClassName={
                      'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                    }
                    previousLinkClassName={
                      'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                    }
                    nextLinkClassName={
                      'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                    }
                    activeLinkClassName={
                      'w-layout-hflex pagination-btn table-pagination-btn-active'
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        addRoleModalFocused && (
          <div className="add-role-popup" style={{ display: "flex" }}>
            <div className="popup_cont sk lvl">
              <div data-name="followers" className="lvl">
                <div className="div-block-147">
                  <div className="text-block-406">Add your role</div>
                  {
                    notification?.msg && (
                      <div className={`add-role-skills-notification-block ${!notification?.success ? "error" : ""}`}>
                        {notification?.msg}
                      </div>
                    )
                  }
                  <img onClick={()=>handleCloseAddRole()}  src="/images/close_2close.png" loading="lazy" data-w-id="6100e533-cd8a-0de0-cbf9-a1883c609d74"
                       alt=""
                       className="cancel_edit_profile" />
                </div>
                <div className="div-block-84 level_rate l">
                  <div className="div-block-85 sp">
                    <div className="w-form">
                      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                            className="form-20"
                            onSubmit={e=>e.preventDefault()}
                            data-wf-page-id="6596e21ac435cac3c66baaef"
                            data-wf-element-id="9e1b50d1-5b53-232b-e10c-b3b63ebfc8d0"><label
                        htmlFor="name">Title *</label><input className="text-field-22 w-input" maxLength="256" value={fields.title} onChange={handleAddRoleInputChange} name="title"
                                                           data-name="Name" placeholder="e.g Data Analyst" type="text"
                                                           id="name" /><label htmlFor="field">Description</label><textarea
                        placeholder="Type in your role description here." maxLength="5000" id="field" value={fields.description} onChange={handleAddRoleInputChange} name="description"
                        data-name="Field" className="textarea-7 w-input"></textarea></form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="popup-f add_content">
                  <div onClick={()=>handleCloseAddRole()} data-w-id="6100e533-cd8a-0de0-cbf9-a1883c609d9f" className="div-block-113 cancel_edit_prof">
                    <div className="text-block-81 cancel_dc color-white">Cancel</div>
                  </div>
                  <div onClick={!submittingRole ? ()=>handleAddRoleSubmission() : ()=>{}} data-w-id="6100e533-cd8a-0de0-cbf9-a1883c609da2" className="div-block-113 ls longer">
                    <div className="text-block-81 add_skill_text">Save &amp; Continue</div> {submittingRole && <span style={{marginLeft:"15px"}}><IBLSpinLoader size={17} color={"#FFF"} /></span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>


  );
};

export default RoleSelection;
