import {
  getIBLSEARCHURL,
  getTenant,
  getUserId,
  getUserName,
  getYearDateRange,
} from '../helpers';
import api from '../../utils/api';

export function getUserCredentialsAPI(callback) {
  api.ibldmcred.getUserCredentials(null, function (data) {
    callback(data?.body);
  });
}

export function getUserMonthlyActivityAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) => api.iblaxdperlearner.perlearnerOrgsUsersInfoRetrieve(...args),
    getTenant(),
    getUserName(),
    { meta: 'true' }
  )((err, data, response) => (err, callback(data), response));
}

export function getUserMetadataAPI(callback) {
  api.ibledxusers.getUsersManageMetadata(null, function (data) {
    callback(data);
  });
}

export function getUserPathwaysAPI(callback) {
  let data = {
    user_id: getUserId(),
  };
  api.ibledxpathway.getUserPathways(data, function (data) {
    callback(data);
  });
}

async function getSelfReportedSkills() {
  return new Promise((resolve, reject) => {
    let data = {
      user_id: getUserId(),
    };
    api.ibldmskills.getUserSelfReportedSkills(data, function (data) {
      let status_code = data.status_code;
      if (status_code === 400) {
        resolve([]);
      }
      // Process data and resolve the promise if successful
      resolve(data?.body?.skills);
    });
  });
}

async function getAssignedSkills() {
  return new Promise((resolve, reject) => {
    let data = {
      user_id: getUserId(),
    };
    api.ibldmskills.getUserSkills(data, function (data) {
      let status_code = data.status_code;
      if (status_code === 400) {
        resolve([]);
      }
      // Process data and resolve the promise if successful
      resolve(data?.body?.skills);
    });
  });
}

async function fetchAndCheckSkills(callback) {
  try {
    const assignedSkills = await getAssignedSkills();
    const selfReportedSkills = await getSelfReportedSkills();

    // Combine assigned and self-reported skills, and check if any skills exist
    let allSkills = [...assignedSkills, ...selfReportedSkills];
    // get only the latest 5 skills
    // allSkills = allSkills.slice(0, 5);
    callback(allSkills);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getUserSkillsAPI(callback) {
  fetchAndCheckSkills(callback);
}

export function getUserCourseDataAPI(callback) {
  let data = {
    user_id: getUserId(),
  };
  api.ibldmcourses.getUserCourses(data, function (data) {
    let allCourseData = [];
    if (data.length !== 0) {
      let courses = data;
      courses.forEach((course, i) => {
        let id = course?.course_id;
        searchGetCourseMeta(id, function (data) {
          allCourseData.push(data?.results[0]);
          if (allCourseData?.length === courses?.length) {
            callback(allCourseData);
          }
        });
      });
    } else {
      callback([]);
    }
  });
}

export function getSkillPointsGraphAPI(callback) {
  api.iblaxdApiCallWrapper(
    (args) => api.iblaxdskillsapi.skillsOrgsSkillsUsersRetrieve(...args),
    getTenant(),
    getUserName()
  )((err, data, response) => (err, data, callback(response)));
}

export function getEngagementDataAPI(callback) {
  let start_date = getYearDateRange().start;
  let end_date = getYearDateRange().end;
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdperlearner.perlearnerOrgsUsersOverviewTimeOverTimeRetrieve(
        ...args
      ),
    getTenant(),
    getUserName(),
    { startDate: start_date, endDate: end_date }
  )((err, data, response) => (err, callback(data.data), response));
}

export function splitIntoWeeksAndMonths(data) {
  var weeksAndMonths = [];
  var week = [];
  var currentMonth = null;
  for (var i = 0; i < data.length; i++) {
    var entry = data[i];
    var date = new Date(entry[0]);
    var dayOfWeek = date.getDay();
    var month = date.getMonth();
    if (dayOfWeek === 0 && week.length > 0) {
      weeksAndMonths.push({ type: 'week', entries: week });
      week = [];
    }

    week.push(entry);
    currentMonth = month;
  }

  if (week.length > 0) {
    weeksAndMonths.push({ type: 'week', entries: week });
  }

  return weeksAndMonths;
}

export function getMonthLabels() {
  var currentDate = new Date();
  var currentMonth = currentDate.getMonth();
  var monthLabels = [];

  for (var i = 0; i < 13; i++) {
    var month = (currentMonth + i) % 12;
    var monthLabel = new Date(0, month).toLocaleString('en-us', {
      month: 'short',
    });
    monthLabels.push(monthLabel);
  }

  return monthLabels;
}

function searchGetCourseMeta(data, callback) {
  const fetchData = async () => {
    try {
      const path =
        '?detail_indice={"_index":"ibl-dm.public.dl_catalog_app_course"}&detail_scope={"course_id":"' +
        data +
        '"}';
      const url = `${getIBLSEARCHURL()}/api/search/search/${path}`;

      const response = await fetch(url, { method: 'GET' });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      callback(responseData);
    } catch (error) {
      console.error(error);
    }
  };
  fetchData();
}
