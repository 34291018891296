import { useEffect, useRef, useState } from 'react';
import { getRecommendedCourses, getUserCoursesMetaData } from './utils/recommended';

function useRecommended() {
  const [recommendedCoursesData, setRecommendedCoursesData] = useState([]);
  const [recommendedCoursesDataLoaded, setRecommendedCoursesDataLoaded] =
    useState(false);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      getRecommendedCourses(getRecommendedCoursesCallback);
      hasFetchedData.current = true;
    }
  }, []);

  const getRecommendedCoursesCallback = (data) => {
    if (Array.isArray(data?.courses)){
      const initialCoursesData = data.courses?.map((course) => ({
        course: course,
        metadata: null,
      }));
      setRecommendedCoursesData(initialCoursesData);
      setRecommendedCoursesDataLoaded(true);


      data?.courses?.forEach((course, index) => {
        getUserCoursesMetaData(course?.course_id).then((metadata) => {
          // Update the specific course's metadata
          setRecommendedCoursesData((currentData) =>
            currentData.map((item, idx) =>
              idx === index ? { ...item, metadata: metadata } : item
            )
          );
        });

      });
    }


  };

  return [recommendedCoursesData, recommendedCoursesDataLoaded];
}

export default useRecommended;
