import { api } from "@iblai/ibl-web-react-common";
import { getTenant, getUserName } from "../helpers";

export function getNotifications(callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdnotificationapi.notificationV1OrgsUsersNotificationsRetrieve(
        ...args
      ),
    getTenant(),
    getUserName(),
    {}
  )((err, data, response) => callback(Array.isArray(response?.body?.results) ? response?.body?.results : []));
}

export function markNotificationAsRead(notificationData, callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdnotificationapi.notificationV1OrgsUsersNotificationsUpdate(
        ...args
      ),
    getTenant(),
    getUserName(),
    notificationData
  )((err, data, response) =>
    callback(
      err,
      data,
      response
    )
  );
}


export function getFilteredNotifications(filterData, callback) {
  api.iblaxdApiCallWrapper(
    (args) =>
      api.iblaxdnotificationapi.notificationV1OrgsUsersNotificationsRetrieve(
        ...args
      ),
    getTenant(),
    getUserName(),
    filterData
  )((err, data, response) =>
    callback(
      err,
      data,
      response
    )
  );
}


  export const fetchNotificationCount = (callback) => {
    api.iblaxdApiCallWrapper(
      (args) =>
        api.iblaxdnotificationapi.notificationV1OrgsUsersNotificationsCountRetrieve(
          ...args
        ),
      getTenant(),
      getUserName(),
      {status: "UNREAD"}
    )((err, data, response) => callback(response));
  };
