import { getTenant, getUserName, userIsAdmin } from './helpers';
import { useEffect, useState } from 'react';

export default function useFreeTrial(){
  const [usage, setUsage] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_ORIGIN = process.env.REACT_APP_IBL_AXD_URL;

  const getPaymentSuccessCallbackUrl = () => {
    return `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/create-organization?redirect-to=${window.location.origin}`;
  };

  const handleUpgrade = async () => {
    const payload = {
      success_url: getPaymentSuccessCallbackUrl(),
      cancel_url: `${window.location.origin}`,
      mode: 'subscription',
      sku: process.env.REACT_APP_IBL_SKILLS_PRODUCT_SKU,
      metered: true,
    };

    const url = `${API_ORIGIN}/api/service/orgs/${getTenant()}/users/${getUserName()}/stripe/checkout-session/`;
    const response = await fetch(url, {
      method: 'POST',
      headers: _getHeaders(),
      body: JSON.stringify(payload)
    });
    if(response.ok){
      const data = await response.json()
      window.location.assign(data?.redirect_to);
    }else{
      console.log(response.text())
    }
  }

  const _getHeaders = () => {
    return {
      Authorization: `Token ${localStorage.getItem('axd_token')}`,
      'Content-Type': 'application/json'
    }
  }

  const loadFreeTrialCount = async () => {
    if(!loading && getTenant() === "main" && !userIsAdmin() && process.env.REACT_APP_IBL_ALLOW_FREE_TRIAL_BANNER === 'true'){
      const url = `${API_ORIGIN}/api/ai-mentor/orgs/${getTenant()}/users/${getUserName()}/free-usage-count`;
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: _getHeaders()
      });

      if(response.ok){
        setUsage(await response.json())
      }
    }
  }

  useEffect(() => {
    loadFreeTrialCount();
  }, []);

  return {usage, loading, handleUpgrade}
}