import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CourseSyllabus = ({
  courseDetails,
  courseLoaded,
  courseID,
  enrollmentStatus,
}) => {
  const navigate = useNavigate();

  const [expandedSections, setExpandedSections] = useState({});

  const handleCourseOutlineClick = (index) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [index]: !prevExpandedSections[index],
    }));
  };

  const handleOutlineClick = (parentIndex, event) => {
    const isEnrolled = enrollmentStatus?.is_enrolled;
    if (isEnrolled) {
      const dataId = event.currentTarget.getAttribute('data-id');
      const newURL = `/courses/${courseID}/course?unit_id=${dataId}`;
      navigate(newURL);
      return;
    }
    window.alert('Please enroll to access the course content');
  };

  return (
    <div className="panel active" data-name="syllabus">
      <div className="about-4" id="about">
        <div className="text-block-319">
          Syllabus
          <br />
        </div>
        <div className="accordion js-accordion" data-ix="fade-up-1">
          {courseLoaded &&
            courseDetails?.children?.map((section, index) => (
              <div className="accordion-item-7 f" key={index}>
                <div
                  className="accordion__title-2"
                  onClick={() => handleCourseOutlineClick(index)}
                  data-w-id={`accordion-title-${index}`}
                >
                  <div className="text-block-318">{section?.display_name}</div>
                  <img
                    alt=""
                    className={`image-188 right ${expandedSections[index] ? 'hidden' : ''}`}
                    src="/images/course_plus.png"
                  />
                  <img
                    alt=""
                    className={`image-188 right ${expandedSections[index] ? '' : 'hidden'}`}
                    src="/images/course_minus.png"
                    width={18}
                  />
                </div>
                <div
                  className="accordion__content-wrap-3"
                  style={{
                    height: expandedSections[index] ? 'auto' : '0px',
                  }}
                >
                  <div className="accordion__content-4">
                    {Array.isArray(section.children) &&
                      section.children.map((childItem, parentIndex) => {
                        if (
                          Array.isArray(childItem.children) &&
                          childItem.children.length > 0
                        ) {
                          return childItem.children.map(
                            (innerChildItem, innerIndex) => (
                              <div
                                className="div-block-541 f"
                                key={`${parentIndex}-${innerIndex}`}
                              >
                                <div className="div-block-542">
                                  <img
                                    alt=""
                                    className="image-188"
                                    src="/images/course_play.png"
                                  />
                                </div>
                                <div
                                  className="div-block-534"
                                  data-id={innerChildItem.id}
                                  onClick={(event) =>
                                    handleOutlineClick(parentIndex, event)
                                  }
                                >
                                  <div className="text-block-315 visited">
                                    {innerChildItem.display_name}
                                  </div>
                                </div>
                                <div className="div-block-529"></div>
                              </div>
                            )
                          );
                        } else {
                          return (
                            <div className="div-block-541 f" key={parentIndex}>
                              <div className="div-block-542">
                                <img
                                  alt=""
                                  className="image-188"
                                  src="/images/course_play.png"
                                />
                              </div>
                              <div
                                className="div-block-534"
                                data-id={childItem.id}
                                onClick={(event) =>
                                  handleOutlineClick(parentIndex, event)
                                }
                              >
                                <div className="text-block-315 visited">
                                  {childItem.display_name}
                                </div>
                              </div>
                              <div className="div-block-529"></div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CourseSyllabus;
