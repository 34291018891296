import { useEffect, useRef } from 'react';
import {
  getUserName,
  getUserSelfReportedSkillsAPI,
} from '@iblai/ibl-web-react-common';
import { useNavigate, useLocation } from 'react-router-dom';

function useStartRole() {
  const hasFetchedData = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!hasFetchedData.current) {
      if (process.env.REACT_APP_IBL_APP_ENABLE_START_ROLE === 'true') {
        getUserSelfReportedSkillsAPI(getUserName(), selfReportedSkillsCallback);
        hasFetchedData.current = true;
      }
    }
  }, []);

  const selfReportedSkillsCallback = (data) => {
    if (data === null && location.pathname !== '/start') {
      navigate(`/start`);
    }
    if (
      Array.isArray(data) &&
      data.length <= 0 &&
      location.pathname !== '/start' // Check if the current path is not '/start'
    ) {
      navigate(`/start`);
    }
  };
}

export default useStartRole;
