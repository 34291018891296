import React, { useContext, useEffect, useState } from 'react';
import {
  AppDataContext,
  ProfileBox,
  SkillLeaderBoardBox,
  useIndex,
  useProfileCourses,
} from '@iblai/ibl-web-react-common';
import Focusskillbox from '../../../components/Focusskillbox/Focusskillbox';
import Credentialsbox from '../../../components/Credentialsbox/Credentialsbox';
import Statengagementbox from '../../../components/Statengagementbox/Statengagementbox';
import Monthlyactivitybox from '../../../components/Monthlyactivitybox/Monthlyactivitybox';
import Skillpointbox from '../../../components/Skillpointbox/Skillpointbox';
import Coursesbox from '../../../components/Coursesbox/Coursesbox';
import './IndexViewContent.css';

const IndexViewContent = () => {
  const [
    userCredentialsData,
    userCredentialsDataLoaded,
    userMonthlyActivityData,
    userMonthlyActivityDataLoaded,
    userPathwaysData,
    userPathwaysDataLoaded,
    userSkillsData,
    userSkillsDataLoaded,
    engagementData,
    engagementDataLoaded,
    skillLeaderBoardData,
    skillLeaderBoardDataLoaded,
    skillPointsGraphData,
    skillPointsGraphDataLoaded,
    ,
  ] = useIndex();
  const { appData, setAppData } = useContext(AppDataContext);
  const [coursesData, courseDataLoaded] = useProfileCourses(
    appData?.userData?.user_display_name
  );

  return (
    <span>
      <div className="div-block-326">
        <div className="div-block-317">
          <div className="panels">
            <div className="panels_cont w_chat">
              <div className="panel-home panel">
                <div className="home_left">
                  <ProfileBox />
                  {
                     ((process.env.REACT_APP_ACTIVE_PROFILE_TABS && process.env.REACT_APP_ACTIVE_PROFILE_TABS.split(",").includes('Skills')) || !process.env.REACT_APP_ACTIVE_PROFILE_TABS) &&
                  <Focusskillbox
                    userSkillsData={userSkillsData}
                    userSkillsDataLoaded={userSkillsDataLoaded}
                  ></Focusskillbox>
                  }
                  <Credentialsbox
                    credentialsData={userCredentialsData}
                    userCredentialsDataLoaded={userCredentialsDataLoaded}
                    appData={appData}
                  ></Credentialsbox>
                </div>
                <div className="home_right">
                  <Statengagementbox
                    engagementData={engagementData}
                    engagementDataLoaded={engagementDataLoaded}
                  ></Statengagementbox>
                  <div className="div-block-33">
                    <div className="div-block-29 w100">
                      <div className="div-block-12 hidden">
                        <div className="text-block-21">Skills</div>
                        <div className="text-block-11">Manage Skills</div>
                      </div>
                      {
                        ((process.env.REACT_APP_ACTIVE_PROFILE_TABS && process.env.REACT_APP_ACTIVE_PROFILE_TABS.split(",").includes('Skills')) || !process.env.REACT_APP_ACTIVE_PROFILE_TABS) &&
                      <SkillLeaderBoardBox
                        percentile={skillLeaderBoardData}
                        percentileLoaded={skillLeaderBoardDataLoaded}
                      />
                      }
                    </div>
                  </div>
                  <div className="div-block-48 home db">
                    <Monthlyactivitybox
                      userMonthlyActivityData={userMonthlyActivityData}
                      userMonthlyActivityDataLoaded={
                        userMonthlyActivityDataLoaded
                      }
                    ></Monthlyactivitybox>
                    {
                      ((process.env.REACT_APP_ACTIVE_PROFILE_TABS && process.env.REACT_APP_ACTIVE_PROFILE_TABS.split(",").includes('Skills')) || !process.env.REACT_APP_ACTIVE_PROFILE_TABS) &&
                    <Skillpointbox
                      skillPointsGraphData={skillPointsGraphData}
                      skillPointsGraphDataLoaded={skillPointsGraphDataLoaded}
                    ></Skillpointbox>
                    }
                  </div>
                  <div className="text-block-10 mg_b_20">Courses</div>
                  <Coursesbox
                    userCourseData={coursesData}
                    userCourseDataLoaded={courseDataLoaded}
                  ></Coursesbox>
                  <div className="text-block-13 more"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};
export default IndexViewContent;
