import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './CourseAbout.css';

const CourseAbout = ({ courseInfo, isContentVisible, handleShowAllClick }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const descriptionRef = useRef(null);
  const sectionsRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (descriptionRef.current && sectionsRef.current) {
        const descriptionOverflow = descriptionRef.current.scrollHeight > 298;
        const sectionsOverflow = sectionsRef.current.scrollHeight > 298;
        setIsOverflow(descriptionOverflow || sectionsOverflow);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [courseInfo?.description, courseInfo?.overview, isContentVisible]);

  const parseSections = (overview) => {
    const sections = overview
      ?.split('</section>')
      .map((section) => section + '</section>');
    return sections
      ?.map((section, index) =>
        section.trim() ? (
          <div key={index} dangerouslySetInnerHTML={{ __html: section }} />
        ) : null
      )
      .filter((section) => section !== null);
  };

  const parsedSections = parseSections(courseInfo?.overview);

  return (
    <div className="panel active" data-name="about">
      <div
        style={{
          maxHeight: isContentVisible ? 'unset' : '298px',
          overflow: 'hidden',
        }}
      >
        <div className="text1" ref={descriptionRef}>
          <section>
            <h2>Course Description</h2>
            <p
              id="courseDescription"
              style={{
                lineHeight: '28px',
                fontSize: '16px',
              }}
              className={'faq-content-2 text-2-content'}
              dangerouslySetInnerHTML={{
                __html: String(courseInfo.description).replace(
                  /(?:\r\n|\r|\n)/g,
                  '<br/>'
                ),
              }}
            ></p>
            {process.env.REACT_APP_ENABLE_COURSE_OVERVIEW_DISPLAY ===
              'true' && (
              <p
                id="courseOverview"
                style={{
                  lineHeight: '28px',
                  fontSize: '16px',
                }}
                className={'faq-content-2 text-2-content'}
                dangerouslySetInnerHTML={{
                  __html: String(courseInfo.overview),
                }}
              ></p>
            )}
          </section>
        </div>
        <div className="faq-question-wrap" ref={sectionsRef}>
          <div className="faq-content-2 text-2-content">
            {/*{parsedSections}*/}
            <p />
          </div>
        </div>
      </div>
      {/*{(isOverflow || (parsedSections && parsedSections.length > 0)) && (*/}
      {isOverflow && (
        <Link
          data-w-id="5ec90528-139f-819b-62fc-cf8a97299e64"
          to="#"
          onClick={() => handleShowAllClick()}
          className="faq-question-bar w-inline-block show_all_cont show_all_cont_custom"
          style={{
            marginBottom: isContentVisible ? '30px' : 'unset',
          }}
        >
          <div>
            <div className="question-title" style={{ color: '#528ac1' }}>
              {isContentVisible ? 'Hide all' : 'Show all'}
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default CourseAbout;
