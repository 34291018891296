import React from 'react';
import CourseEnrollmentButton from '../CourseEnrollmentButton/CourseEnrollmentButton';
import CourseInfo from '../CourseInfo/CourseInfo';

const CourseHeader = ({
  courseInfo,
  enrollmentStatus,
  enrollmentStatusLoaded,
  imageUrl,
  handleImageError,
  courseID,
  children,
}) => {
  return (
    <>
      <div className="left-col-3">
        <div
          className="about-box-2"
          id="w-node-adb8c6bd-8ff9-86ea-d38f-f1efe5481473-c66baadf"
        >
          <div className="div-block-539">
            <div className="course-detail-name-block">
              <div className="text-block-310">{courseInfo?.display_name}</div>
              <div
                className="text-block-310"
                style={{ textTransform: 'capitalize' }}
              >
                {courseInfo?.subject || ''}
              </div>
              <div className="div-block-543 hide">
                <div className="div-block-544 ins">
                  <img
                    alt=""
                    className="image-185"
                    src="images/warren-wong-VVEwJJRRHgk-unsplash.jpg"
                  />
                </div>
                <div className="div-block-528">
                  <div className="text-block-317">Eliott Hanson</div>
                </div>
              </div>
            </div>
            <div className="div-block-533 hide">
              <div>Offered By</div>
              <img
                alt=""
                className="image-8"
                loading="lazy"
                src="images/log_d.png"
              />
            </div>
          </div>
        </div>
        <div className="about-box-2 desktop-hidden">
          <div className="div-block-536">
            <div className="vid-header-3 w-clearfix">
              <img
                src={imageUrl}
                onError={handleImageError}
                alt="course image"
                className="video w-video w-embed c-image"
              />
              {enrollmentStatusLoaded && (
                <CourseEnrollmentButton
                  enrollmentStatus={enrollmentStatus}
                  coursePrice={courseInfo.course_price}
                  courseID={courseID}
                  courseName={courseInfo?.display_name}
                />
              )}
            </div>

            <CourseInfo courseInfo={courseInfo} />
          </div>
        </div>
        {children}
      </div>
    </>
  );
};
export default CourseHeader;
