import { useEffect, useRef, useState } from 'react';
import { getRoles, getSkills, saveRoleOrSkills } from './helpers';

function useSkill() {
  const [searchedSkills, setSearchedSkills] = useState({
    results: [],
    num_pages: 1,
  });
  const [skillsLoaded, setSkillsLoaded] = useState(false);

  const addNewSkills = (data, successCallback, errorCallback) => {
    saveRoleOrSkills(data, (status)=>{
      if([200, 201].includes(status)){
        successCallback()
      }else{
        errorCallback()
      }
    }, "skills")
  }
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      getSkills(
        (data) => {
          setSearchedSkills(data);
          setSkillsLoaded(true);
        },
        1,
        [],
        ''
      );
      hasFetchedData.current = true;
    }
  }, []);

  return { searchedSkills, setSearchedSkills, skillsLoaded, setSkillsLoaded, addNewSkills };
}

export default useSkill;
