import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const IblSpinLoader = ({ color, size = 40, containerHeight = '100%' }) => {
  const mainColor = process.env.REACT_APP_PRIMARY_COLOR || '#6cafe1';
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: containerHeight,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TailSpin
        visible={true}
        height={size}
        width={size}
        color={color || mainColor}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

export default IblSpinLoader;